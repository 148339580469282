<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-8 col-12">Mismatched Voters List</div>
    <div class="
        col-lg-4 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link " id="mismatch-address-tab" data-bs-toggle="tab" data-bs-target="#mismatchaddressdetail"
        type="button" role="tab" aria-controls="vehicle-details" @click="redirectmismatchedaddressurl()"
        aria-selected="false">
        By Voters Address
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="mismatch-name-tab" data-bs-toggle="tab" data-bs-target="#mismatchnamedetail"
        type="button" role="tab" aria-controls="customer-details" aria-selected="true">
        By Voters Name
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade show active" id="mismatchaddressdetail" role="tabpanel"
      aria-labelledby="mismatch-address-tab">
      <div class="company-section-outer">
        <div class="layout-content-section custom-bg-datatable-transparent">
          <div class="custom-ultima-datatable custom-voter-datable custom-bg-datatable-transparent" style="height: calc(100vh - 188px)">
            <DataTable :value="mismatchNameVoterList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
              :paginator="true" :rows="30" :totalRecords="totalRecords" @page="changePage($event)"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              :lazy="true" dataKey="ak1" v-model:selection="selectedcheckboxvoter" :loading="loading">
              <!-- <template #paginatorstart>
                <div>
                  <span class="voter-only-circle-pagination me-1"></span>
                  <span class="voter-circle-pagination-text">Voter</span>
                  <span class="voter-user-circle-pagination me-1"></span>
                  <span class="voter-circle-pagination-text">User</span>
                  <span class="voter-karyakartas-circle-pagination me-1"></span>
                  <span class="voter-circle-pagination-text">Karyakartas</span>
                </div>
              </template> -->
              <!-- <template #paginatorend></template> -->
              <template v-if="!loading" #empty>No records found.</template>
              <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
              <Column headerStyle="width: 3%" bodyStyle="width: 3%" class="justify-content-center"
                selectionMode="multiple"></Column>
              <Column header="Select All" headerStyle="width: 96%" bodyStyle="width: 96%">
                <template #body="{ data }">
                  <div class="company-detail-box-outer mb-3">
                    <div class="row g-2">
                      <div class="
                              col-lg-2 col-md-2 col-12
                              voter-profile-col
                              d-flex
                              align-items-center
                            ">
                        <div class="customer-label-group">
                          <div class="d-flex align-items-center">
                            <div :class="data.ak29 == 3
                              ? 'border-voter-user' : data.ak29 == 2 ? 'border-voter-karyakartas'
                                : 'border-voter-only'
                              ">
                              <img v-if="(data.ak90 == null || data.ak90 == '') && (data.ak33 == null || data.ak33 == '')"
                                src="/assets/images/profile.svg" class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                    " alt="" style="width: 72px; height: 72px;" />
                              <img v-if="data.ak90 != null && data.ak90 != ''"
                                :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/voterphoto/' + data.ak90"
                                class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                              <img
                                v-if="(data.ak33 != null && data.ak33 != '') && (data.ak90 == null || data.ak90 == 'NULL' || data.ak90 == '')"
                                :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + data.ak33"
                                class="
                                      rounded
                                      bg-light
                                      me-auto
                                      d-block
                                      voter-photo-img
                                    " alt="" />
                              <img v-if="data.ak29 == 1" src="/assets/images/icon/voters-tab-icon.svg" alt=""
                                title="Voter" width="16" />
                              <img v-if="data.ak29 == 2" src="/assets/images/icon/users-tab-icon.svg" alt=""
                                title="Karyakartas" width="16" />
                              <img v-if="data.ak29 == 3" src="/assets/images/icon/customers-tab-icon.svg" alt=""
                                title="User" width="16" />
                            </div>
                          </div>
                          <div class="text-center from-label-value mt-1"
                                v-if="(data.ak120 != null && data.ak120 != '')">{{ data.ak120 }}</div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12 voter-name-col">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2 d-inline-block">
                              <label class="form-label">Voter Name</label>
                              <div class="from-label-value text-capitalize">
                                <span style="width: 233px;" class="text-ellipsis"
                                :title="data.ak5 ? firstCapitalize(data.ak5) : ''">{{ data.ak5 ? data.ak5 : "N/A" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"><span
                                  style="width: 50px; display: inline-block;">Gender</span><span
                                  class="mx-1 vertical-pipe-color">|</span><span>Blood Group</span></label>
                              <div class="from-label-value text-capitalize">
                                <span style="width: 50px; display: inline-block;">{{
                                  data.ak22
                                  ? data.ak22 == 1
                                    ? "Male"
                                    : data.ak22 == 2
                                      ? "Female"
                                      : "Others"
                                  : "N/A"
                                }}</span><span class="mx-1 vertical-pipe-color">|</span><span>{{ data.ak21 ? data.ak21 : "N/A" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Vidhan Sabha</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.ak61 ? data.ak61 : "N/A" }}<span class="ms-1" v-if="data.ab23">({{ data.ab23
                                }})</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-2 col-md-2 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"><span style="width: 87px; display: inline-block;">Date of
                                  Birth</span><span class="mx-1 vertical-pipe-color">|</span><span>Age</span></label>
                              <div class="from-label-value">
                                <span style="width: 87px; display: inline-block;">{{ format_date(data.ak18)
                                }}</span><span class="mx-1 vertical-pipe-color">|</span><span>{{ data.ak19 ? data.ak19 : "N/A" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"><span style="width: 87px; display: inline-block;">Voter Id</span><span class="mx-1 vertical-pipe-color">|</span><span>Part No.</span></label>
                              <div class="from-label-value text-capitalize">
                                <span style="width: 87px; display: inline-block;">{{ data.ak35 ? data.ak35 : "N/A"
                                }}</span><span class="mx-1 vertical-pipe-color">|</span><span>{{ data.ak85 ? data.ak85 : "N/A" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Lok Sabha</label>
                              <div class="from-label-value text-capitalize">
                                {{ data.ak63 ? data.ak63 : "N/A" }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2 float-start">
                              <label class="form-label"><span
                                  style="width: 127px; display: inline-block;">Profession</span><span
                                  class="mx-1 vertical-pipe-color">|</span><span
                                  style="width: 95px; display: inline-block;">Designation</span></label>
                              <div class="from-label-value text-capitalize">
                                <span style="width: 127px;" class="text-ellipsis" :title="data.ak67 ? firstCapitalize(data.ak67) : ''">{{ data.ak67 ?
                                  data.ak67 : "N/A"
                                }}</span><span class="mx-1 float-start">|</span><span style="width: 95px;"
                                  class="text-ellipsis" :title="data.ak84 ? firstCapitalize(data.ak84) : ''">{{ data.ak84 ? data.ak84 : "N/A" }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label"><span
                                  style="width: 127px; display: inline-block;">Area</span><span
                                  class="mx-1 vertical-pipe-color">|</span><span>Pincode</span></label>
                              <div class="from-label-value text-capitalize">
                                <span style="width: 127px;" class="text-ellipsis">
                                  <span v-if="data.ak44 != null && data.ak53 == ''" :title="data.ak44 ? firstCapitalize(data.ak44) : ''">{{ data.ak44
                                  }}</span>
                                  <span v-if="data.ak53 != ''" :title="data.ak53 ? firstCapitalize(data.ak53) : ''">{{ data.ak53 }}</span>
                                  <span v-if="data.ak53 == null || data.ak53 == '' && data.ak44 == null">N/A</span>
                                </span><span class="mx-1 vertical-pipe-color">|</span><span>
                                  <span v-if="data.ak58 != null">{{ data.ak58 }}</span>
                                  <span v-if="data.ak97 != null && data.ak58 == null">{{ data.ak97 }}</span>
                                  <span v-if="data.ak97 == null && data.ak58 == null">N/A</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Reg. Date & Time</label>
                              <div class="from-label-value text-capitalize">
                                {{ format_timestamp(data.z501) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-3 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Email</label>
                              <div class="from-label-value"
                                v-if="this.allowshowemailvoterfk == 0 && (data.ak23 != '') && (data.ak23 != null)">
                                {{ maskedEmail(data.ak23) }}
                              </div>
                              <div class="from-label-value"
                                v-if="this.allowshowemailvoterfk == 1 && (data.ak23 != '' && data.ak23 != null)">
                                {{ data.ak23 }}
                              </div>
                              <div class="from-label-value" v-if="data.ak23 == '' || data.ak23 == null">
                                N/A
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Mobile</label>
                              <div class="from-label-value" v-if="this.allowshowmobilevoterfk == 0">
                                +91-{{ data.ak24 ? maskedNumber(data.ak24) : "N/A" }}
                              </div>
                              <div class="from-label-value" v-if="this.allowshowmobilevoterfk == 1">
                                +91-{{ data.ak24 ? data.ak24 : "N/A" }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="
                                    customer-label-group
                                    mb-2
                                    d-flex
                                    justify-content-end
                                  ">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="mismatchnamedetail" role="tabpanel" aria-labelledby="mismatch-name-tab">
      Mismatched By Voters Name
    </div>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import moment from 'moment';
export default {
  data() {
    return {
      mismatchNameVoterList: [],
      totalRecords: 0,
      page_no: 0,
      selectedcheckboxvoter: null,
      loading: false,
      allowshowmobilevoterfk: '',
      allowshowemailvoterfk: '',
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.getmismatchedvoters({ is_mismatched_adderss: 1 });
    this.client_info = JSON.parse(localStorage.client_info);
    this.voterstorageimgpath = this.client_info.maa24;
    this.user_info = JSON.parse(localStorage.user);
    this.allowshowmobilevoterfk = this.user_info.ak105;
    this.allowshowemailvoterfk = this.user_info.ak106;
  },
  methods: {
    getmismatchedvoters(e) {
      this.loading = true;
      this.ApiService.getmismatchedvoters(e).then((data) => {
        if (data.success == true) {
          this.mismatchNameVoterList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.mismatchNameVoterList = null;
          this.totalRecords = 0;
          this.loading = false;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.getmismatchedvoters({ page_no: this.page_no });
    },
    redirectmismatchedaddressurl() {
      this.$router.push("/voters/mismatchedbyaddress");
    },
    format_datetime(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY hh:mm A");
      } else {
        return 'N/A';
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    format_timestamp(value) {
      if (value) {
        var localTime = new Date(value * 1000).toUTCString();
        return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
      } else {
        return 'N/A';
      }
    },
    maskedNumber(getvalue) {
      const maskedDigits = 'X'.repeat(getvalue.length - 4);
      const FirstThreeDigits = getvalue.slice(0, 2);
      const lastThreeDigits = getvalue.slice(-2);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    maskedEmail(getvalue) {
        const [name, domain] = getvalue.split('@');
        const [firstDomain, sublastdomain, lastdomain] = domain.split('.');
        if(name.length == 1 && !lastdomain) {
            return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}`;
        } else if(name.length == 1 && lastdomain) {
            return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}.${lastdomain}`;
        } else {
            return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
        }
    },
    firstCapitalize(e) {
      const str = e;
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

      }
      const str2 = arr.join(" ");
      return str2;
    },
  },
};
</script>
<style scoped>
.company-detail-box-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 16px 16px 0;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  width: 100%;
}
.p-highlight .company-detail-box-outer{
  background: rgba(255, 179, 0, 0.12);
}
.customer-label-group {
  margin-bottom: 20px;
}

.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  word-break: break-word;
}

.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 514px; */
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
</style>